import { CounterService } from 'src/app/shared/services/counter.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';

import { Empresa } from 'src/app/shared/models/empresa';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CarrinhoService } from 'src/app/shared/services/carrinho.service';
import { DataService } from 'src/app/shared/services/data.service';
import { EmpresaService } from 'src/app/shared/services/empresa.service';
import { StoreService } from 'src/app/shared/services/store.service';
import { NullUtil } from 'src/app/shared/utils/null.util';

@Injectable({
    providedIn: 'root'
})
export class CompanyResolverGuard implements Resolve<Empresa> {

    company: Empresa;

    constructor(
        private authService: AuthService,
        private companyService: EmpresaService,
        private dataService: DataService,
        private shoppingCartService: CarrinhoService,
        private shoppingCartCounter: CounterService,
        private storeService: StoreService
    ) { }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Empresa> {
        if (route.params && route?.params.storeId) {
            let currentStoreId = this.companyService.getStoreId();

            if (!NullUtil.isSet(currentStoreId) || (NullUtil.isSet(currentStoreId) && (currentStoreId !== route.params.storeId))) {
                if (NullUtil.isSet(this.authService.loggedUser))
                    this.authService.logout().subscribe();

                this.resetData();
                currentStoreId = route.params.storeId;
            }

            this.companyService.setStoreId(currentStoreId);

            if (!this.companyService.vendor || !NullUtil.isSet(this.companyService.vendor.id)) {
                this.company = await this.storeService.getCompanyByStoreId().toPromise()
                    .then(company => company).catch(() => null);

                if (!this.company)
                    return of(null).toPromise();

                this.companyService.setCompanyId(this.company.id);

                return of(this.company).toPromise();
            }

            return of(this.companyService.vendor).toPromise();
        }

        return of(undefined).toPromise();
    }

    private resetData() {
        this.authService.resetAuth();
        this.dataService.resetData();
        this.companyService.setVendor(null);
        this.shoppingCartService.removeShoppingCart();
        this.shoppingCartCounter.shoppingCartCounter.next(0);
    }

    // FIXME: Método criado para remover as variáveis atuais em sessão
    private removeOldStorageData() {
        const oldVariables = [
            'pdag_store_promotion_basket',
            'pdag_store_app_shopping_cart',
            'pdag_store_vendors',
            'pdag_store_auth',
            'current_user',
            'pdag_store_auth_redirect',
            'pdag_store_remember_me',
            'pdag_store_auth_token',
            'pdag_store_current_store_code',
            'pdag_store_current_store_id',
            'pdag_store_feedback_message',
            'pdag_store_vendor',
            'pdag_store_web_token',
        ];
        oldVariables.forEach(variable => {
            localStorage.removeItem(variable);
            sessionStorage.removeItem(variable);
        });
    }
}
