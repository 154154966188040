import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';

import {
    AUTH,
    AUTH_CURRENT_USER,
    AUTH_REMEMBER_ME,
    AUTH_TOKEN,
    CURRENT_STORE_CODE,
    PUBLIC_ENDPOINTS,
    PUBLIC_ENDPOINTS_EXCEPT,
    WEB_TOKEN
} from 'src/app/shared/consts/domains';
import { OmniEnviroment } from 'src/app/shared/consts/omni-enviroment';
import { JWTPayload } from 'src/app/shared/implements/jwt-payload';
import { Login } from 'src/app/shared/implements/login';
import { Cliente } from 'src/app/shared/models/cliente';
import { Empresa } from 'src/app/shared/models/empresa';
import { MessagesService } from 'src/app/shared/services/messages.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { NullUtil } from 'src/app/shared/utils/null.util';
import { environment } from 'src/environments/environment';

const jwtHelper = new JwtHelperService();

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private authHeaders: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ZXNjb2xwaS1jbGllbnQ6QGVzY29scGkxMDY4MA=='
    });
    private readonly urlAuth = `${environment.apiUrl}/oauth/token`;
    private readonly urlRevoke = `${environment.apiUrl}/tokens/revoke`;
    private currentAuthSubject: BehaviorSubject<JWTPayload>;
    private customer$: BehaviorSubject<Cliente>;
    private tokenSubject: BehaviorSubject<string>;

    currentAuth: Observable<JWTPayload>;

    constructor(
        private http: HttpClient,
        private messagesService: MessagesService,
        private storageService: StorageService,
        private toastService: ToastService,
        private navCtrl: NavController
    ) {
        this.currentAuthSubject = new BehaviorSubject<JWTPayload>({} as JWTPayload);
        this.customer$ = new BehaviorSubject<Cliente>(null);
        this.currentAuth = this.currentAuthSubject.asObservable();
        this.tokenSubject = new BehaviorSubject(null);
    }

    get companyId() {
        return this.storageService.secureSessionStorage.getItem(CURRENT_STORE_CODE);
    }

    get currentAuthValue(): JWTPayload {
        return this.currentAuthSubject.value;
    }

    get loggedUser(): Cliente {
        return this.customer$.value || <Cliente> this.storageService.secureLocalStorage.getItem(AUTH_CURRENT_USER);
    }

    set loggedUser(customer: Cliente) {
        this.storageService.secureLocalStorage.setItem(AUTH_CURRENT_USER, customer);
        this.customer$.next(customer);
    }

    getRememberedUser(): Login {
        return <Login> this.storageService.secureLocalStorage.getItem(AUTH_REMEMBER_ME);
    }

    get token(): string {
        return this.storageService.secureLocalStorage.getItem(AUTH_TOKEN) || this.tokenSubject.value;
    }

    get webToken(): string {
        return this.storageService.secureLocalStorage.getItem(WEB_TOKEN);
    }

    set webToken(token: string) {
        this.storageService.secureLocalStorage.setItem(WEB_TOKEN, token);
    }

    /**
     * Obtem o usuário logado na loja.
     */
    getLoggedCustomer() {
        return this.http.get<Cliente>(`${environment.apiUrl}/clientes/${this.companyId}/obter`, {
            params: { email: this.currentAuthSubject.value.user_name }
        }).pipe(
            take(1),
            tap(customer => {
                this.storageService.secureLocalStorage.setItem(AUTH_CURRENT_USER, JSON.stringify(customer));
                this.customer$.next(customer);
            })
        );
    }

    /**
     * Valida a consistência do token. Se ele foi expirado ele deverá ser
     * renovado enquanto existir o access token.
     */
    hasTokenExpired() {
        return jwtHelper.isTokenExpired(this.token);
    }

    /**
     * Efetua o login na aplicação com o e-mail e senha do cliente. Se bem sucedido,
     * é retornado o access token de acesso e registrado enquanto a sessão estiver ativa.
     * @param email string
     * @param senha string
     */
    login(email: string, senha: string) {
        const token = this.storageService.secureLocalStorage.getItem(WEB_TOKEN);
        const body = `username=${email}:${this.companyId}:${token}&password=${senha}&grant_type=password`;

        return this.http.post<any>(this.urlAuth, body, { headers: this.authHeaders, withCredentials: true }).pipe(
            take(1),
            map(auth => auth && auth.access_token ? auth : null),
            shareReplay()
        );
    }

    /**
     * Efetua o logout da Aplicação.
     */
    logout() {
        const httpParams = new HttpParams()
            .append('omniEnviroment', OmniEnviroment.STORE);
        return this.http.delete(this.urlRevoke, { headers: this.authHeaders, params: httpParams }).pipe(
            take(1),
            tap(() => this.resetAuth())
        );
    }

    isAuthUrl(url: string) {
        return url === this.urlAuth || url === this.urlRevoke;
    }

    /**
     * Verifica se a URL de uma requisição HTTP é pública.
     * @param request: HttpRequest<any>
     * @return boolean
     */
    isPublicUrl(request: HttpRequest<any>) {
        return request.method.toUpperCase() === 'GET'
            && PUBLIC_ENDPOINTS.filter(endpoint => request.url.indexOf(endpoint) >= 0).length > 0
            && !this.isPublicUrlExcept(request.url);
    }

    /**
     * Caso uma respectiva loja não esteja identificada, a mesma é redirecionada
     * para a notificação de empresa não encontrada.
     * @param empresa Empresa
     */
    hasIdentifiedCompany(company: Empresa) {
        if (!NullUtil.isSet(company) || Object.keys(company).length === 0) {
            this.navCtrl.navigateRoot(['/store-not-found']);
            return;
        }
    }

    /**
     * Redireciona o usuário para a página de login salvando a rota de destino para que a mesma seja acessada
     * após o usuário se autenticar.
     * @param url string
     */
    loginRedirect(url: string, storeId: string) {
        this.toastService.warning(this.messagesService.getMessage('MSG.AVISO.005'));
        this.navCtrl.navigateRoot(
            [`${storeId}/store/login`, { storeId, returnTo: `${storeId}/store/${url}` }],
            { animated: true, animationDirection: 'forward', replaceUrl: true }
        );
    }

    /**
     * Remove a sessão de autenticação do usuário.
     */
    removeRememberedUser() {
        this.storageService.secureLocalStorage.removeItem(AUTH_REMEMBER_ME);
    }

    /**
     * Reinicia a autenticação do usuário.
     */
    resetAuth() {
        this.storageService.secureLocalStorage.removeItem(AUTH);
        this.storageService.secureLocalStorage.removeItem(AUTH_CURRENT_USER);
        this.storageService.secureLocalStorage.removeItem(AUTH_REMEMBER_ME);
        this.storageService.secureLocalStorage.removeItem(AUTH_TOKEN);
        this.storageService.secureLocalStorage.removeItem(WEB_TOKEN);
        this.loggedUser = null;
        this.currentAuthSubject.next(null);
        this.customer$.next(null);
        this.tokenSubject.next(null);
    }

    /**
     * Renova o refresh token da aplicação.
     */
    renewToken() {
        if (this.hasTokenExpired()) {
            const body = 'grant_type=refresh_token';

            this.storageService.secureLocalStorage.removeItem(AUTH);
            this.storageService.secureLocalStorage.removeItem(AUTH_CURRENT_USER);
            this.storageService.secureLocalStorage.removeItem(AUTH_TOKEN);
            this.loggedUser = null;
            this.currentAuthSubject.next({} as JWTPayload);

            return this.http.post<any>(this.urlAuth, body, { headers: this.authHeaders, withCredentials: true }).pipe(
                map(auth => auth && auth.access_token ? auth : null),
                shareReplay()
            );
        }
    }

    /**
     * Armazena o token de acesso obtido da API.
     * @param token string
     */
    saveToken(token: string) {
        const payload = jwtHelper.decodeToken(token) as JWTPayload;
        this.currentAuthSubject.next(payload);
        this.storageService.secureLocalStorage.setItem(AUTH, payload);
        this.storageService.secureLocalStorage.setItem(AUTH_TOKEN, token);
        this.tokenSubject.next(token);

        return this.http.get<Cliente>(`${environment.apiUrl}/clientes/${this.companyId}/obter`, {
            params: { email: payload.user_name }
        }).pipe(
            take(1),
            tap(customer => customer)
        );
    }

    setRememberedUser(login: Login) {
        this.storageService.secureLocalStorage.setItem(AUTH_REMEMBER_ME, login);
    }   

    /**
     * Verifica se a URL pertence à exceção de um endpoint público.
     * @param url: string
     * @return boolean
     */
    private isPublicUrlExcept(url: string) {
        return PUBLIC_ENDPOINTS_EXCEPT.filter(endpoint => url.indexOf(endpoint) >= 0).length > 0;
    }

}
