import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { APP_PROMOTION_BASKET, APP_SHOPPING_CART } from 'src/app/shared/consts/domains';
import { CarrinhoDeCompras } from 'src/app/shared/models/carrinho-compras';
import { CestaDePromocao } from 'src/app/shared/models/cesta-promocao';
import { ItemDoPedido } from 'src/app/shared/models/item-do-pedido';
import { Pedido } from 'src/app/shared/models/pedido';
import { PersonalizacaoDeProduto } from 'src/app/shared/models/personalizacao-de-produto';
import { DataService } from 'src/app/shared/services/data.service';
import { ArrayUtil } from 'src/app/shared/utils/array.util';
import { NullUtil } from 'src/app/shared/utils/null.util';
import { ResourceService } from './resource.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class CarrinhoService extends ResourceService<Pedido> {

    private carrinhoSubject: BehaviorSubject<CarrinhoDeCompras> = new BehaviorSubject(null);

    constructor(
        http: HttpClient,
        private dataService: DataService,
        private storageService: StorageService) {
        super(http, 'carrinho');
    }

    get currentShoppingCart(): Observable<CarrinhoDeCompras> {
        return this.carrinhoSubject.asObservable();
    }

    get shoppingCart(): CarrinhoDeCompras {
        return JSON.parse(this.storageService.secureSessionStorage.getItem(APP_SHOPPING_CART));
    }

    set shoppingCart(carrinhoDeCompras: CarrinhoDeCompras) {
        this.updateShoppingCart(carrinhoDeCompras);
    }

    get promotionBasket(): CestaDePromocao {
        return JSON.parse(this.storageService.secureSessionStorage.getItem(APP_PROMOTION_BASKET));
    }

    set promotionBasket(cesta: CestaDePromocao) {
        this.storageService.secureSessionStorage.setItem(APP_PROMOTION_BASKET, JSON.stringify(cesta));
    }

    /**
     * Calcula e retorna o valor total do carrinho de compras.
     */
    calculateTotalShoppingCart(): number {
        let totalValue = 0;

        if (!ArrayUtil.isEmpty(this.shoppingCart.itensDoPedido)) {
            this.shoppingCart.itensDoPedido.map(item => totalValue += item.valor);
        }
        if (!ArrayUtil.isEmpty(this.shoppingCart.cestasDePromocao)) {
            this.shoppingCart.cestasDePromocao.map(item => totalValue += item.valor);
        }

        return totalValue;
    }

    /**
     * Obtem o contador de itens do carrinho de compras
     */
    getCartCounter() {
        let cart: any;
        let counter = 0;

        if (!NullUtil.isSet(cart)) {
            if (!NullUtil.isSet(this.storageService.secureSessionStorage.getItem(APP_SHOPPING_CART))) {
                return 0;
            }

            cart = this.shoppingCart;
        }

        if (!cart) {
            return 0;
        }
        if (cart && !ArrayUtil.isEmpty(cart.itensDoPedido)) {
            counter += cart.itensDoPedido.length;
        }
        if (cart && !ArrayUtil.isEmpty(cart.cestasDePromocao)) {
            counter += cart.cestasDePromocao.length;
        }

        return counter;
    }

    /**
     * Obtém os personalizações da categoria informada
     * @param categoriaId number
     */
    getCustomizationsToPromotion(categoriaId: number) {
        const params = new HttpParams().append('categoriaId', categoriaId.toString());
        return this.http.get(
            `${this.api}/${this.endpoint}/${this.companyId}/personalizacoes-categoria/promocao`, { params }
        );
    }

    /**
     * Obtém os personalizações de um respectivo produto.
     * @param productId number
     */
     getProductCustomizations(productId: number): Observable<PersonalizacaoDeProduto[]> {
        return this.http.get<PersonalizacaoDeProduto[]>(
            `${this.api}/${this.endpoint}/${this.companyId}/personalizacoes-produtos/${productId}`
        );
    }

    /**
     * Atualiza o estado do carrinho
     * @param shoppingCart any[]
     */
    updateShoppingCart(shoppingCart: CarrinhoDeCompras) {
        this.storageService.secureSessionStorage.setItem(APP_SHOPPING_CART, JSON.stringify(shoppingCart));
        this.carrinhoSubject.next(shoppingCart);
    }

    hasShoppingCartItems() {
        return !ArrayUtil.isEmpty(this.shoppingCart.itensDoPedido)
            || !ArrayUtil.isEmpty(this.shoppingCart.cestasDePromocao);
    }

    removeSavedCategory() {
        this.dataService.removeData('categoryId');
    }

    /**
     * Inclui itens do pedido no carrinho de compras.
     * @param orderItem ItemDoPedido[]
     */
    addOrderItems(orderItem: ItemDoPedido[]) {
        const shoppingCart = <CarrinhoDeCompras> this.shoppingCart || {};

        if (ArrayUtil.isEmpty(shoppingCart.itensDoPedido)) {
            shoppingCart.itensDoPedido = orderItem;
        } else {
            orderItem.forEach(item => shoppingCart.itensDoPedido.push(item));
        }

        this.shoppingCart = shoppingCart;
        this.updateShoppingCart(this.shoppingCart);
    }

    /**
     * Inclui itens do pedido por promoção no carrinho de compras.
     * @param promotionBasket CestaDaPromocao[]
     */
    addPromotionBasket(promotionBasket: CestaDePromocao) {
        const shoppingCart = this.shoppingCart ||  {} as CarrinhoDeCompras;

        if (ArrayUtil.isEmpty(shoppingCart.cestasDePromocao)) {
            shoppingCart.cestasDePromocao = [];
        }

        shoppingCart.cestasDePromocao.push(promotionBasket);
        this.shoppingCart = shoppingCart;
        this.updateShoppingCart(this.shoppingCart);
    }

    /**
     * Obtém os personalizações da categoria informada
     * @param categoriaId number
     */
    getCustomizations(categoriaId: number) {
        const params = new HttpParams().append('categoriaId', categoriaId.toString());
        return this.http.get(
            `${this.api}/${this.endpoint}/${this.companyId}/personalizacoes-categoria`, { params }
        );
    }

    /**
     * Obtém os personalizações da categoria informada
     * @param categoriaId number
     */
    getCustomizationsByPromotion(categoriaId: number) {
        const params = new HttpParams().append('categoriaId', categoriaId.toString());
        return this.http.get(
            `${this.api}/${this.endpoint}/${this.companyId}/personalizacoes-categoria/promocao`, { params }
        );
    }

    /**
     * Obtém os valores dos opcionais de acordo com o ID da personalização informada.
     * @param personalizacaoId number
     */
    getOptionalValues(personalizacaoId: number): any {
        const params = new HttpParams().append('personalizacaoId', personalizacaoId.toString());
        return this.http.get(`${this.api}/${this.endpoint}/${this.companyId}/valores-personalizacoes`, { params });
    }

    /**
     * Remove o carrinho de compras em aberto e seus respectivos itens.
     */
    removeShoppingCart() {
        this.storageService.secureSessionStorage.removeItem(APP_SHOPPING_CART);
        this.updateShoppingCart(null);
    }

    /**
     * Remove a cesta com promoção em aberto.
     */
    removePromotionBasket() {
        this.storageService.secureSessionStorage.removeItem(APP_PROMOTION_BASKET);
    }

}
