import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppInterceptor } from 'src/app/shared/interceptors/app.interceptor';
import { AppComponent } from './app.component';

registerLocaleData(ptBr);

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        AppRoutingModule,
        HttpClientModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        IonicModule.forRoot(),
    ],
    providers: [
        NativeGeocoder,
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
