import { Injectable } from '@angular/core';

import { Messages } from 'src/app/shared/consts/messages';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {

    constructor() { }

    getMessage(key: string, params?: string[]): string {
        if (!Messages[key]) {
            return null;
        }
        if (!params || params.length === 0) {
            return (Messages[key].length > 0 ? Messages[key] : null);
        }

        let message = Messages[key];
        params.map((param, i) => {
            message = message.replace(`{${i}}`, param);
        });

        return message;
    }

}
