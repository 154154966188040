import * as moment from 'moment';

import { Semana } from 'src/app/shared/enums/dia-da-semana.enum';

export class DateUtil {

    /**
     * Obtem a data corrente.
     */
    static now = (): Date => moment().toDate();

    /**
     * Método responsável por converter uma string de hora para Date
     * @param hora string
     */
    static parseToHours = (hora: string): Date => moment(hora, 'HH:mm').toDate();

    /**
     * Converte uma string de data para Date
     * @param data string
     * @param pattern string
     */
    static parseToDate = (data: string, pattern?: string): Date => moment(data, pattern || 'DD/MM/YYYY').toDate();

    /**
     * Converte uma string em uma data em Moment.
     * @param data any
     * @param pattern string
     */
    static parseToMoment = (data: any, pattern?: string): moment.Moment => moment(data, pattern || 'DD/MM/YYYY');


    /**
     * Converte uma data em string para milisegundos.
     * @param date any
     */
    static parseToTimeMillis(date: any) {
        const data = new Date();
        data.setDate(date.substr(0, 2));
        data.setMonth(date.substr(3, 2) - 1);
        data.setFullYear(date.substr(6, 4));

        return data.getTime();
    }

    /**
     * Método responsável por comparar e retornar o dia da semana
     * @param date string
     */
    static getDayOfWeek = (date: Date) => {
        const dayOfWeek = date.getDay() + 1;
        switch (dayOfWeek) {
            case Semana.DOMINGO.id:
                return Semana.DOMINGO.prefixo;
            case Semana.SEGUNDA_FEIRA.id:
                return Semana.SEGUNDA_FEIRA.prefixo;
            case Semana.TERCA_FEIRA.id:
                return Semana.TERCA_FEIRA.prefixo;
            case Semana.QUARTA_FEIRA.id:
                return Semana.QUARTA_FEIRA.prefixo;
            case Semana.QUINTA_FEIRA.id:
                return Semana.QUINTA_FEIRA.prefixo;
            case Semana.SEXTA_FEIRA.id:
                return Semana.SEXTA_FEIRA.prefixo;
            case Semana.SABADO.id:
                return Semana.SABADO.prefixo;
            default:
                return Semana.TODOS_OS_DIAS.prefixo;
        }
    }

    /**
     * Verifica se é a data corrente.
     */
    static isSame = (data: string) => moment(data, 'DD/MM/YYYY').isSame();

    /**
     * Verifica se uma data é a do dia ou futura.
     */
    static isSameOrAfter = (data: string) => moment(data, 'DD/MM/YYYY').isSameOrAfter();

    /**
     * Calcula a diferença entre duas datas em função do tipo de perído (dias, meses, anos, horas, 
     * minutos, segundos, etc.).
     * @param initialDate string
     * @param finalDate string
     * @param pattern string
     * @param unity moment.unitOfTime.Diff
     */
    static diffPeriod = (
        initialDate: string,
        finalDate: string,
        pattern: string = 'DD/MM/YYYY',
        unity: moment.unitOfTime.Diff = 'year') => {
        const initial = moment(initialDate, pattern);
        const final = moment(finalDate, pattern);

        return final.diff(initial, unity);
    }

    static diffHours = (data: string) => {
        return moment(new Date()).diff(DateUtil.parseToDate(data, 'DD/MM/YYYY HH:mm:ss'), 'hours');
    }

    /**
     * Cálcula a diferença de anos entre a data atual e uma respecitva data.
     * @param data string
     */
    static diffYears = (data: string) => {
        const dataOrigem = moment(data, 'DD/MM/YYYY');
        const dataAtual = moment(new Date());

        return dataAtual.diff(dataOrigem, 'year');
    }

    /**
     * Verifica se uma data está presente em um respectivo intervalo.
     * @param data Moment
     * @param valorDiferenca any
     * @param unit string
     */
    static isBetween = (initialDate: string, finalDate: string, comparingDate: string, pattern = 'DD/MM/YYYY') => {
        const initial = moment(initialDate, pattern);
        const final = moment(finalDate, pattern);
        const current = moment(comparingDate, pattern);

        return current.isBetween(initial, final);
    }

    /**
     * Verifica se uma respectiva data é passada
     * @param initialDate Moment
     */
    static isPast = (initialDate: string, finalDate: string,  pattern: string = 'DD/MM/YYYY') => {
        const currDate = moment(initialDate, pattern);
        const now = moment(finalDate, pattern);

        return currDate.isBefore(now);
    }

    /**
     * Verifica se a data correspondente é o dia atual.
     * @param date Date
     */
    static isCurrentDate = (date: Date) => {
        const currDate = moment(date);
        const now = moment();

        return now.diff(currDate, 'days') === 0;
    }

    /**
     * Verifica se a data informada é valida de acordo com o padrão informado.
     * @param data string
     * @param pattern string
     */
    static isValidDate = (data: string, pattern: string) => {
        return moment(data, pattern).isValid();
    }

    /**
     * Verifica se uma data é futura.
     * @param data string
     * @param pattern string
     */
    static isFuture = (data: string, pattern: string) => {
        return moment(data, pattern).isAfter(moment());
    }

    /**
     * Formata e retorna uma data no formado definido de acordo com o padrão.
     * @param data Date
     * @param pattern string
     */
    static formatDate(data: Date, pattern: string = 'DD/MM/YYYY') {
        return moment(data).format(pattern);
    }

    /**
     * Calcula e retorna o pŕoximo dia.
     * @oaram date string
     */
    static getNextDate = (date: Date): Date => DateUtil.parseToMoment(date).add(1, 'day').toDate();

    /**
     * Calcula e retorna o dia anterior.
     * @param date string
     */
    static getPreviousDate = (date: Date): Date => DateUtil.parseToMoment(date).subtract(1, 'day').toDate();

    /**
     * Retorna uma data em timestamp no formato UNIX.
     * @param timestamp: number
     */
    static unixTimestamp = (timestamp: number) => moment.unix(timestamp);

    /**
     * Incrementa a quantidade de dias em função do multiplicador.
     * @param amount number
     */
    static addDays = (amount: number) => moment().add(amount, 'days').toDate();

    /**
     * Obtem os minutos calculados a partir de um valor numérico.
     */
    static getMinutes = (minutes: number): number => {
        if (minutes > 59) {
            return minutes % 60;
        }

        return minutes;
    }

    /**
     * Obtem as horas calculadas a partir de um valor numérico.
     */
    static parseMinutesToHours = (minutesToParse: number) => {
        if (minutesToParse > 59) {
            return Math.floor(minutesToParse / 60);
        }

        return 0;
    }

    /**
     * Atualiza o horário de uma data alterando os minutos ou segundos.
     * @param date Date
     * @param hours number
     * @param minutes number
     * @param seconds number
     */
    static updateTime = (date: Date, hours?: number, minutes?: number, seconds?: number) => {
        date = moment(date).set({ h: hours || 0, m: minutes || 0, s: seconds || 0}).toDate();

        return date;
    }

    /**
     * Verifica se um intervalo de tempo é valido.
     * @param initialDate Date
     * @param finalDate Date
     */
    static isValidRange(initialDate: Date, finalDate: Date) {
        return moment(initialDate).isBefore(moment(finalDate));
    }
}
