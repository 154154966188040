import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { CURRENT_STORE_CODE, CURRENT_STORE_ID, VENDOR } from 'src/app/shared/consts/domains';
import { Empresa } from 'src/app/shared/models/empresa';
import { EmpresaConfiguracao } from 'src/app/shared/models/empresa-configuracao';
import { StatusDaLoja } from 'src/app/shared/models/status-da-loja';
import { StorageService } from 'src/app/shared/services/storage.service';
import { DateUtil } from 'src/app/shared/utils/date.util';
import { NumberUtil } from 'src/app/shared/utils/number.util';
import { StringUtil } from 'src/app/shared/utils/string.util';
import { ResourceService } from './resource.service';

@Injectable({
    providedIn: 'root'
})
export class EmpresaService extends ResourceService<Empresa> {

    private vendorSubject: BehaviorSubject<Empresa> = new BehaviorSubject(null);

    constructor(
        http: HttpClient,
        private storageService: StorageService
    ) {
        super(http, 'empresas');
        this.vendorSubject = new BehaviorSubject<Empresa>(
            this.storageService.secureSessionStorage.getItem(VENDOR) || {} as Empresa
        );
    }

    get configuracoes(): EmpresaConfiguracao[] {
        return this.vendor.configuracoesDaEmpresa;
    }

    // tslint:disable-next-line: adjacent-overload-signatures
    get vendor() {
        return this.vendorSubject.value || this.storageService.secureSessionStorage.getItem(VENDOR);
    }

    /**
     * Obtem um registro de configuração de uma respectiva empresa a partir do nome da configuração.
     * @param storeId string
     * @param configName string
     */
    findCompanyConfigByName(storeId: string, configName: string): Observable<EmpresaConfiguracao> {
        return this.http.get<EmpresaConfiguracao>(
            `${this.api}/portal/${this.endpoint}/${storeId}/configuracao/${configName}`
        ).pipe(take(1));
    }

    /**
     * Obtem o registro de uma respectiva empresa.
     */
    getCompany() {
        return this.http.get<Empresa>(`${this.api}/${this.endpoint}/${this.getCompanyId()}/`);
    }

    getCompanyId() {
        return this.storageService.secureSessionStorage.getItem(CURRENT_STORE_CODE);
    }

    /**
     * Obtem os registros das filiais da empresa
     * @param companyId number
     */
    getCompanyBranches(companyId: number): Observable<Empresa[]> {
        return this.http.get<Empresa[]>(`${this.api}/${this.endpoint}/${companyId}/obter-filiais`);
    }

    getBackground(company: Empresa) {
        if (!StringUtil.isBlank(company.planoDeFundoStore)) {
            return {
                'background-image': `url("${company.planoDeFundoStore}")`
            };
        }

        return {
            'background-image': `url("${company.segmento.caminhoFoto}")`
        };
    }

    /**
     * Retorna as horas estimadas para entrega de pedidos.
     * @returns number
     */
    getDeliveryHours() {
        if (NumberUtil.isNotNullOrZero(this.vendor.tempoDeEntrega)) {
            const hours = DateUtil.parseMinutesToHours(this.vendor.tempoDeEntrega);

            return hours > 0 ? hours : null;
        }

        return 0;
    }

    /**
     * Retorna os minutos estimados para entrega de pedidos.
     * @returns number
     */
    getDeliveryMinutes() {
        if (NumberUtil.isNotNullOrZero(this.vendor.tempoDeEntrega)) {
            const minutes = DateUtil.getMinutes(this.vendor.tempoDeEntrega);

            return minutes > 0 ? minutes : null;
        }

        return 0;
    }

    /**
     * Retorna o tempo estimado para entrega formatado.
     * @returns string
     */
    getDeliveryTime() {
        let time = '';

        if (this.getDeliveryHours() > 0)
            time += `${this.getDeliveryHours()}h${this.getDeliveryHours() > 1 ? 's' : ''}`;

        if (this.getDeliveryMinutes() > 0)
            time += ` ${this.getDeliveryMinutes()} min.`;

        return time;
    }

    getVendorBackground() {
        if (!StringUtil.isBlank(this.vendor.planoDeFundoStore)) {
            return {
                'background-image': `url("${this.vendor.planoDeFundoStore}")`
            };
        }

        return {
            'background-image': `url("${this.vendor.segmento.caminhoFoto}")`
        };
    }

    /**
     * Obtem uma Configuração do Aplicativo da empresa a partir do nome da configuração.
     * @param configName string
     */
    /*getConfig(configName: string): EmpresaConfiguracao {
        // TODO: Substituir configuração atual
        // return this.vendor.configuracoesDaEmpresa.filter(
        //     config => config.nome === configName)[0] || null;
        return null;
    }*/

    /**
     * Retorna o Store da ID da respectiva loja.
     */
    getStoreId(): string {
        return this.storageService.secureSessionStorage.getItem(CURRENT_STORE_ID) || null;
    }

    /**
     * Retorna os minutos estimados para retirada de pedidos.
     * @returns number
     */
    getTakeoutMinutes() {
        if (NumberUtil.isNotNullOrZero(this.vendor.tempoDeRetirada)) {
            const minutes = DateUtil.getMinutes(this.vendor.tempoDeRetirada);

            return minutes > 0 ? minutes : null;
        }

        return 0;
    }

    /**
     * Retorna a hora estimada para retirada de pedidos.
     * @returns number
     */
    getTakeoutHours() {
        if (NumberUtil.isNotNullOrZero(this.vendor.tempoDeRetirada)) {
            const hours = DateUtil.parseMinutesToHours(this.vendor.tempoDeRetirada);

            return hours > 0 ? hours : null;
        }

        return 0;
    }

    /**
     * Retorna o tempo de retirada estimado formatado.
     * @returns string
     */
    getTakeoutTime() {
        let time = '';

        if (this.getTakeoutHours() > 0)
            time += `${this.getTakeoutHours()}h${this.getTakeoutHours() > 1 ? 's' : ''}`;

        if (this.getTakeoutMinutes() > 0)
            time += ` ${this.getTakeoutMinutes()} min.`;

        return time;
    }

    /**
     * Testa se existe registro do estabelecimento invocado na loja.
     */
    hasSelectedVendor() {
        return this.vendor && this.vendor.id;
    }

    /**
     * Verifica se o Cashback está disponível.
     * @return boolean
     */
    // TODO: Refatorar obtenção dos dados do cashback
     /*isCashbackAvaliable() {
        const cashbackConfig = this.getConfig('config-ativar-cashback');

        if (!NullUtil.isSet(cashbackConfig)) {
            return false;
        }

        return cashbackConfig.valor === '1';
    }*/

    /**
     * Atualiza os dados da home: Tempos de entrega, estado da loja (aberto/fechado) e último pedido do cliente.
     * @param clienteId number
     */
    refreshHome(clienteId: number): Observable<any> {
        const params = new HttpParams().append('clienteId', `${clienteId}`);
        return this.http.get(`${this.api}/${this.endpoint}/${this.getCompanyId()}/atualizar`, { params });
    }

    /**
     * Atualiza os dados da home: Tempo de entrega, estado da loja (aberto/fechado) e último pedido do 
     * cliente sem cadastro.
     * @param orderId number
     * @returns 
     */
    refreshGuestHome(orderId = 0): Observable<StatusDaLoja> {
        return this.http.get<StatusDaLoja>(`${this.api}/${this.endpoint}/${this.getCompanyId()}/atualizar/sem-cadastro/${orderId}`);
    }

    setCompanyId(companyId: number) {
        this.storageService.secureSessionStorage.setItem(CURRENT_STORE_CODE, companyId);
    }

    /**
     * Atribui o código de identificação da loja em LocalStorage.
     * @param storeId string
     */
    setStoreId(storeId: string) {
        this.storageService.secureSessionStorage.setItem(CURRENT_STORE_ID, storeId);
    }

    /**
     * Registra a empresa vinculada ao vendedor.
     * @param company Empresa
     */
    setVendor(company: Empresa) {
        this.storageService.secureSessionStorage.setItem(VENDOR, company);
        this.vendorSubject.next(company);
    }

}
