import { Injectable } from '@angular/core';

import { StringUtil } from 'src/app/shared/utils/string.util';
import { AuthService } from 'src/app/shared/services/auth.service';

import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

    constructor(private service: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!StringUtil.externalHost(request.url) && !this.service.isAuthUrl(request.url)
            && !this.service.isPublicUrl(request)) {
            if (!this.service.hasTokenExpired() && this.service.token.indexOf('error') === -1) {
                const cloned = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${this.service.token}`,
                    }
                });

                return next.handle(cloned);
            }
        }

        return next.handle(request);
    }

}
