export class ArrayUtil {
    /**
     * Retorna um novo array com todos os objetos duplicados removidos.
     * @param arr array
     * @param comp string
     * @return arr Array
     */
    static getUnique = (arr, comp) => {
        const unico = arr.map(e => e[comp])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e]).map(e => arr[e]);

        return unico;
    }

    /**
     * Retorna verdadeiro se um array está vazio.
     * @param arr Array
     * @return Array
     */
    static isEmpty = (arr: any[]) => !arr || arr.length === 0;

    /**
     * Ordena e retorna um array de objetos ordenados por ordem crescente ou
     * decrescente.
     * @param arr Array
     * @param attr String
     * @param orderBy string
     * @return Array
     */
    static sortCollection = (arr: any, attr: string, orderBy = 'asc') => {
        if (!ArrayUtil.isEmpty(arr)) {
            if (orderBy === 'desc') {
                arr = arr.sort((a, b) => +(a[attr] < b[attr]) || +(a[attr] === b[attr]) - 1);
            } else {
                arr = arr.sort((a, b) => +(a[attr] > b[attr]) || +(a[attr] === b[attr]) - 1);
            }
        }

        return arr;
    }

    /**
     * Ordena e retorna um array de objetos ordenados por ordem crescente ou decrescente 
     * por um atributo filho.
     */
    static sortCollectionByChild = (arr: any[], attr: string, attrChild: string, orderBy = 'asc') => {
        if (!ArrayUtil.isEmpty(arr)) {
            if (orderBy === 'desc') {
                arr = arr.sort((a, b) => +(a[attr][attrChild] < b[attr][attrChild])
                    || +(a[attr][attrChild] === b[attr][attrChild]) - 1);
            } else {
                arr = arr.sort((a, b) => +(a[attr][attrChild] > b[attr][attrChild])
                    || +(a[attr][attrChild] === b[attr][attrChild]) - 1);
            }
        }

        return arr;
    }

}
